a {
  padding: 0 0.35rem;
  transition: 0.1s;

  &:link {
    color: inherit;
  }

  &:hover, &:visited:hover {
    color: inherit;
    opacity: 0.4;
  }

  &:visited  {
    color: inherit;
  }
}