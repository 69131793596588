$accentColor: #1e2e40 !global;
//$accentColor: #111921 !global;
$borderradius: 5px !global;

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 1, 2015 */

@font-face {
    font-family: 'montserrat';
    src: url('./rsc/fonts/montserrat-regular-webfont.eot');
    src: url('./rsc/fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./rsc/fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./rsc/fonts/montserrat-regular-webfont.woff') format('woff'),
    url('./rsc/fonts/montserrat-regular-webfont.ttf') format('truetype'),
    url('./rsc/fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
 
  @font-face {
    font-family: 'montserrat';
    src: url('./rsc/fonts/montserrat-bold-webfont.eot');
    src: url('./rsc/fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./rsc/fonts/montserrat-bold-webfont.woff2') format('woff2'),
    url('./rsc/fonts/montserrat-bold-webfont.woff') format('woff'),
    url('./rsc/fonts/montserrat-bold-webfont.ttf') format('truetype'),
    url('./rsc/fonts/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: bold;
    font-style: normal;
 
  }
 
  @font-face {
    font-family: 'montserratblack';
    src: url('./rsc/fonts/montserrat-black-webfont.eot');
    src: url('./rsc/fonts/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('./rsc/fonts/montserrat-black-webfont.woff2') format('woff2'),
    url('./rsc/fonts/montserrat-black-webfont.woff') format('woff'),
    url('./rsc/fonts/montserrat-black-webfont.ttf') format('truetype'),
    url('./rsc/fonts/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
  }