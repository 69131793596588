
.App {
    overflow-x: hidden;
    position: relative;
}

.paragraph {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}