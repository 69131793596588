@import "../../variables";

.intro {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  //text-shadow: 1px 0 black, 0px 1px black, -1px 0 black, 0px -1px black; //, 1px 1px black, -1px -1px black
  text-align: center;
  transform-origin: center center;
  perspective: 2000px;
  background: black;

  #background-video{

    min-height: 100%;
    min-width: 100%;
    float: left;
    background: rgba(0,0,0,0.5);
    z-index: -5;
    top: 0;
    left: 0;
    opacity: 0.7;
    padding: none;
    position: absolute;
    transform: translateZ(-100px) scale(1.2);
    
  }

  .effectWrapper {
    .effect {
      position: absolute;
      color: rgba(255,255,255,0.2);
      transform-origin: inherit;
      //font-size: 23vw;
      font-size: 11.5vw;
      font-weight: bolder;
      // color: rgba(0,0,0,0.2);
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-text-stroke-width: 0.02em;
      -webkit-text-stroke-color: #fff;
    }
  }

  &::after {
    background: $accentColor;
    content: "";
    width: 150%;
    height: 100vh;
    top: 100vh;
    transform-origin: right top;
    transform: rotate(5deg) translateX(-25%);
    left: 0;
    position: absolute;
  }

  .downArrow {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    color: white;
    transition: transform 0.2s, opacity 0.2s;
    margin: 0 auto;
    z-index: 100;
    font-size: 2rem;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow( 0 0 10px rgba(0, 0, 0, 1));

    &:hover {
      transform: translateY(-5px) translateX(-50%);
      opacity: 0.7;
    }
  }
}

/**
Navigation
 */

.navigation {
  z-index: 5;
  position: absolute;
  overflow: hidden;
  text-align: left;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  color: $accentColor;
  padding: 1rem;
  border-radius: $borderradius;
  //border-top: 1px $accentColor solid;
  background: rgba($color:#fff, $alpha: 0.4);
  backdrop-filter: blur(6px);

  a {
    align-self: center;
    text-align: center;
    margin: 0 auto;
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: lighter;
    //color: white;
    font-size: 1rem;
    transition: 0.2s;
    padding: 0 1.3rem;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      opacity: 0.4;
      transform: translateY(-2px);
      cursor: pointer;
    }
  }

  div:first-child {
    margin: 1.5rem auto 0;
    padding: 0 4rem;
    flex-grow: 3;
  }

  div:last-child {
    font-size: 1rem;
    text-align: center;
    margin: 2rem auto 1rem;
    flex-grow: 3;
  }
}